import api from "../../../core/helpers/api-services";
import { logMessage } from "../../../core/actions/common-actions";
import { buildDynamicQueryUrl, getCommunicationPlatformByName, handleCommunicationPlatformName } from "../../common/actions/general-actions";
import { AppConfigProps } from "../../../core/settings/app-config";
import {
  CONNECTED_ACCOUNT,
  ORDER_PAYMENT_INFO,
  PUBLISHER_KEY,
  QUOTE_ESTIMATOR,
  QUOTE_PROGRESS_PAGE,
  SELECTED_PRODUCT_ID,
  QUOTE_QUOTE_ESTIMATOR_RESET,
} from "../../../store/constants";
import _, { cloneDeep } from "lodash";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import { QuoteConstants } from "../settings/quote-constants";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { QuoteEstimatorTableColumns } from "../settings/quote-estimator-table-columns";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const actionFileName = "quote-actions.js";

export function getResources(productObject, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/catalog/products-resources",
        productObject,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getResources()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function getDocuments(productObject, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/catalog/products-documents",
        productObject,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getDocuments()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function setQuoteProgressPage(page) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_PROGRESS_PAGE,
        payload: page,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setQuoteEstimatorInfoProductRecords(
  quoteEstimatorInfo,
  productRecords
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          productRecords: productRecords,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setQuoteEstimatorInfoQuoteSubscriberDetails(
  quoteEstimatorInfo,
  subscriberDetails
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          subscriberDetails: subscriberDetails,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setQuoteEstimatorInfoServiceAndConnectionRecords(
  quoteEstimatorInfo,
  serviceAndConnectionRecords
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          serviceAndConnectionRecords: serviceAndConnectionRecords,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setQuoteEstimatorInfoServiceAndConnectionCost(
  quoteEstimatorInfo,
  serviceNrcSubTotalCost,
  serviceMrcSubTotalCost,
  serviceTotalCost
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          serviceNrcSubTotalCost: serviceNrcSubTotalCost,
          serviceMrcSubTotalCost: serviceMrcSubTotalCost,
          serviceTotalCost: serviceTotalCost,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function getQuoteProductList(productRecords) {
  let products = [{ key: "", text: "" }];
  if (productRecords && productRecords.length > 0) {
    productRecords.forEach((product) => {
      products.push({ key: product.productId, text: product.productName });
    });
  }
  return products;
}

export function resetQuoteEstimatorInfo(quoteEstimatorInfo) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          serviceNrcSubTotalCost: null,
          serviceMrcSubTotalCost: null,
          serviceTotalCost: null,
          serviceDocumentRecords: [],
          generalDocumentRecords: [],
          onboardServiceDocumentRecords: [],
          communicationPlatformDocumentRecords: [],
          productRecords: [],
          serviceAndConnectionRecords: [],
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function quoteEstimatorDataReset(quoteEstimatorInfo) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_QUOTE_ESTIMATOR_RESET,
        payload: quoteEstimatorInfo,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setQuoteEstimatorInfoSubscriberQuoteRecords(
  quoteEstimatorInfo,
  subscriberQuoteRecords
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          subscriberQuoteRecords: subscriberQuoteRecords,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setQuoteEstimatorInfoResourceRecords(
  quoteEstimatorInfo,
  resourceRecords
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          resourceRecords: resourceRecords,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}
export function setQuoteEstimatorInfoOnboardServiceDocumentRecords(
  quoteEstimatorInfo,
  onboardServiceDocumentRecords
) {
  return async function (dispatch) {
    try {
      dispatch({
        type: QUOTE_ESTIMATOR,
        payload: {
          ...quoteEstimatorInfo,
          onboardServiceDocumentRecords: onboardServiceDocumentRecords,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setOrderPublisherKey(publisherKey) {
  return async function (dispatch) {
    try {
      dispatch({
        type: PUBLISHER_KEY,
        payload: publisherKey,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export function setOrderConnectedAccount(orderConnectedAccount) {
  return async function (dispatch) {
    try {
      dispatch({
        type: CONNECTED_ACCOUNT,
        payload: orderConnectedAccount,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

export async function getProductsAvailable(
  languageCode,
  availabilityType,
  availabilityName,
  currencyCode,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    let queryParams = {
      availabilityType: availabilityType,
      availabilityName: availabilityName,
      languageCode: languageCode,
      currencyCode: currencyCode,
    };
    let baseUrl = `/catalog/products/availability`;
    let url = buildDynamicQueryUrl(baseUrl, queryParams);
    api
      .get(AppConfigProps.operatorConnectAPIPrefix + url, cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getProductsAvailable()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function saveQuote(products, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/catalog/quotes",
        products,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">saveQuote()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function requestSupport(contactObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/website/service-requests",
        contactObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">requestSupport()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function addSubscriber(quoteId, subscriberDetails, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix +
          "/catalog/quotes/" +
          quoteId +
          "/subscribers",
        subscriberDetails,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">saveQuote()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function savePayment(quoteId, paymentDetails, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix +
          "/catalog/quotes/" +
          quoteId +
          "/payments",
        paymentDetails,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">saveQuote()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function getQuotesOrdersSearch(
  languageCode,
  keyword,
  extractType,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    let queryParams = {
      keyword: keyword,
      languageCode: languageCode,
    };

    if (extractType !== null && extractType !== "") {
      queryParams = {
        ...queryParams,
        extractType: extractType,
      };
    }

    let baseUrl = `/catalog/quotes`;
    let url = buildDynamicQueryUrl(baseUrl, queryParams);
    api
      .get(AppConfigProps.operatorConnectAPIPrefix + url, cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getQuotesOrdersSearch()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function getQuote(languageCode, quoteId, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .get(
        AppConfigProps.operatorConnectAPIPrefix +
          `/catalog/quotes/${quoteId}?languageCode=${languageCode}`,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">getQuote()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function setStripePaymentIntent(paymentInfoObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .post(
        AppConfigProps.operatorConnectAPIPrefix + "/catalog/payments",
        paymentInfoObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">saveQuote()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function updatePaymentInfo(paymentId, paymentInfoObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    api
      .put(
        AppConfigProps.operatorConnectAPIPrefix +
          "/catalog/payments/" +
          paymentId,
        paymentInfoObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (api.isCancel(err)) {
          logMessage(
            AppConfigProps.log.severityLevel.warning,
            actionFileName + ">updatePaymentInfo()",
            AppConfigProps.httpStatusCode.clientClosedRequest,
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function setOrderPaymentInfo(orderPaymentInfo) {
  return async function (dispatch) {
    try {
      dispatch({
        type: ORDER_PAYMENT_INFO,
        payload: orderPaymentInfo,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}
export function handleServiceAddress(object, id) {
  let addresses = cloneDeep(object);
  let address = null;
  if (id && id !== "" && addresses?.length > 0) {
    addresses = addresses.find((add) => add.addressIdNum === id);
    if (addresses) address = addresses?.fullAddress;
  }
  return address;
}
export function setSelectedProductId(productId, currencyId) {
  return async function (dispatch) {
    try {
      dispatch({
        type: SELECTED_PRODUCT_ID,
        payload: {
          productId: productId,
          currencyId: currencyId,
        },
      });
    } catch (err) {
      throw Error(err);
    }
  };
}
export function capitalizeLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else return "";
}

export async function quoteDownloadPdf(
  websiteTemplate,
  quoteEstimatorInfo,
  intlNamespace,
  intlCommonNamespace,
  communicationPlatformsData
) {
  let tableRecords = [];
  let tableColumns = [];
  let tableHead = [];
  let customerPrice = 0;
  let currencySymbol = "";
  if (
    quoteEstimatorInfo?.serviceAndConnectionRecords &&
    quoteEstimatorInfo?.serviceAndConnectionRecords.length > 0
  ) {
    let renderChargeTypeLabel = (chargeType) => {
      let chargeTypeValue = "";
      QuoteConstants.CHARGE_TYPE_LIST.forEach((charge) => {
        if (
          chargeType?.toLowerCase().trim() === charge.key?.toLowerCase().trim()
        ) {
          chargeTypeValue = charge.text;
        }
      });
      return chargeTypeValue;
    };

    let customerPriceUnitPrice = (currencySymbol, sellingPrice) => {
      return (
        currencySymbol +
        parseFloat(sellingPrice).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    };

    let customerTotalPrice = (currencySymbol, customerPrice) => {
      return (
        currencySymbol +
        parseFloat(customerPrice).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    };

    let customerPriceAmount = (currencySymbol, quantity, sellingPrice) => {
      let sellingValuePrice =
        parseFloat(quantity ?? "0").toFixed(2) *
        parseFloat(sellingPrice).toFixed(2);
      return (
        currencySymbol +
        parseFloat(sellingValuePrice).toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    };

    quoteEstimatorInfo?.serviceAndConnectionRecords.forEach((rec) => {
      let termPlan = "";
      let productName = `${rec.productName} (${rec.productCode})`;
      if (
        rec.termPlan?.toLowerCase().trim() ===
        OperatorConnectConstants.GENERAL.YES?.toLowerCase().trim()
      ) {
        termPlan = IntlUtil.getText(intlCommonNamespace, "content.yes");
      } else {
        termPlan = IntlUtil.getText(intlCommonNamespace, "content.no");
      }

      let availabilityName = rec.availabilityName
        ? `${IntlUtil.getText(intlNamespace, "content.country")}: ${
            rec.availabilityName
          }`
        : "";
      let communicationPlatform = rec.communicationPlatform
        ? `${IntlUtil.getText(intlNamespace, "content.platform")}: ${
          handleCommunicationPlatformName(getCommunicationPlatformByName(communicationPlatformsData,rec.communicationPlatform))
          }`
        : "";
      let connection = rec.connection
        ? `${IntlUtil.getText(intlNamespace, "content.planOrConnection")}: ${
            rec.connection
          }`
        : "";
      termPlan = termPlan
        ? `${IntlUtil.getText(
            intlNamespace,
            "content.termsAndConditions"
          )}: ${termPlan}`
        : "";
  let productDescription= rec.productDescription?rec.productDescription+"\n\n":"\n"
      let productDetails = "";
      
        productDetails =
          productName +
          "\n" +
           productDescription+
           
          availabilityName +
          "\n" +
          connection +
          "\n" +
          communicationPlatform +
          "\n" +
          termPlan +
          "\n";
    
       

      currencySymbol = rec.currencySymbol;
      if (rec.pricing?.length > 0) {
        rec.pricing.forEach((price) => {
          if (price.sellingPrice !== null) {
            customerPrice =
              customerPrice +
              parseFloat(rec?.quantity ?? "0").toFixed(2) *
                parseFloat(price?.sellingPrice).toFixed(2);
          }
        });
      }
      let totalPrice =
        rec?.pricing?.length > 0 &&
        rec?.pricing?.every((price) => price.sellingPrice <= 0);
      if (!totalPrice)
        rec.pricing = rec.pricing.filter((char) => char.sellingPrice > 0);
      if (rec.pricing?.length > 0)
        tableRecords.push({
          productDetails: productDetails,
          quantity: rec?.quantity,
          chargeName: _.map(rec.pricing, (rec) => rec.chargeName).join("\n\n"),
          chargeType: _.map(rec.pricing, (rec) =>
            renderChargeTypeLabel(rec?.chargeType)
          ).join("\n\n"),
          customerPriceUnitPrice: _.map(rec.pricing, (price) =>
            customerPriceUnitPrice(rec.currencySymbol, price.sellingPrice)
          ).join("\n\n"),
          customerPriceAmount: _.map(rec.pricing, (price) =>
            customerPriceAmount(
              rec.currencySymbol,
              rec?.quantity,
              price.sellingPrice
            )
          ).join("\n\n"),
        });
    });

    QuoteEstimatorTableColumns(intlNamespace)
      .filter((rec) => rec.key !== "actions")
      .forEach((res) => {
        if (res.fieldName === "productName") {
          res.fieldName = "productDetails";
        }
        tableColumns.push({ header: res.name, dataKey: res.fieldName });
        tableHead.push(res.name);
      });
    let comments = quoteEstimatorInfo?.customizedQuoteRecord?.comments;
    let imageHeight = 0;

    let pdfDoc = new jsPDF("p", "pt", "a4");
    let x = 0;
    let y = 0;
    pdfDoc.setFontSize(12);
    pdfDoc.setFontSize(18);
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.setTextColor("#231f20");
    pdfDoc.text(
      285,
      imageHeight + 55,
      IntlUtil.getText(intlNamespace, "content.order"),
      { align: "center" }
    );
    pdfDoc.text(285, imageHeight + 56, "_____", { align: "center" });

    pdfDoc.setFont("helvetica", "normal");
    pdfDoc.setFontSize(11);

    let introductionNotesLines = "";
    if (
      quoteEstimatorInfo?.customizedQuoteRecord?.introductionNotes &&
      quoteEstimatorInfo?.customizedQuoteRecord?.introductionNotes.length > 0
    ) {
      introductionNotesLines = pdfDoc.splitTextToSize(
        quoteEstimatorInfo?.customizedQuoteRecord?.introductionNotes,
        510
      );
      pdfDoc.text(40, imageHeight + 80, introductionNotesLines);
      imageHeight = imageHeight + 5;
    }
    let height =
      pdfDoc.getTextDimensions(introductionNotesLines).h + imageHeight;

    let quoteBodyNumber = [];
    if (quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber) {
      quoteBodyNumber = [
        {
          numberTitle: quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber
            ? IntlUtil.getText(intlNamespace, "content.reference") +
              ": " +
              quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber
            : "",
          dateValue: "",
        },
      ];
    }

    autoTable(pdfDoc, {
      startY: height + 80,
      body: quoteBodyNumber,
      columns: [{ header: "", dataKey: "numberTitle" }],
      columnStyles: {
        0: { cellWidth: "auto" },
      },
      didParseCell: (table) => {
        if (table.row.index === 0) {
          table.cell.styles.fillColor = "#ffffff";
        }
      },
      willDrawCell: (data) => {
        if (data.section === "head") {
          if (data.table.pageCount > 1) {
            return false;
          }
        }
        if (data.column.index === 0 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
        if (data.column.index === 1 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
      },
    });
    autoTable(pdfDoc, {
      startY: pdfDoc.lastAutoTable.finalY + 0,
      body: [
        {
          dateTitle:
            IntlUtil.getText(intlNamespace, "content.date") +
            ": " +
            moment().format("MMM D, YYYY"),
          dateValue: "",
        },
      ],
      columns: [{ header: "", dataKey: "dateTitle" }],
      columnStyles: {
        0: { cellWidth: "auto" },
      },
      didParseCell: (table) => {
        if (table.row.index === 0) {
          table.cell.styles.fillColor = "#ffffff";
        }
      },
      willDrawCell: (data) => {
        if (data.section === "head") {
          if (data.table.pageCount > 1) {
            return false;
          }
        }
        if (data.column.index === 0 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
        if (data.column.index === 1 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
      },
    });

    let quoteBodyName = [];
    if (quoteEstimatorInfo?.subscriberDetails?.quoteName) {
      quoteBodyName = [
        {
          nameKey: quoteEstimatorInfo?.subscriberDetails?.quoteName
            ? IntlUtil.getText(intlNamespace, "content.name") +
              ": " +
              quoteEstimatorInfo?.subscriberDetails?.quoteName
            : "",
          dateValue: "",
        },
      ];
    }

    autoTable(pdfDoc, {
      startY: pdfDoc.lastAutoTable.finalY + 0,
      body: quoteBodyName,
      columns: [{ header: "", dataKey: "nameKey" }],
      columnStyles: {
        //1: { textColor: "#0075C9" }
      },
      didParseCell: (table) => {
        if (table.row.index === 0) {
          table.cell.styles.fillColor = "#ffffff";
        }
      },
      willDrawCell: (data) => {
        if (data.section === "head") {
          if (data.table.pageCount > 1) {
            return false;
          }
        }
        if (data.column.index === 0 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
        if (data.column.index === 1 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
      },
    });

    let quoteBodyEmailAndPhone = [];
    if (quoteEstimatorInfo?.subscriberDetails?.email) {
      quoteBodyEmailAndPhone = [
        {
          dataKey:
            IntlUtil.getText(intlNamespace, "content.email") +
            ": " +
            quoteEstimatorInfo?.subscriberDetails?.email,
          numKey: quoteEstimatorInfo?.subscriberDetails?.phone
            ? IntlUtil.getText(intlNamespace, "content.phone") +
              ": " +
              quoteEstimatorInfo?.subscriberDetails?.phone
            : "",
        },
      ];
    }

    autoTable(pdfDoc, {
      startY: pdfDoc.lastAutoTable.finalY + 0,
      body: quoteBodyEmailAndPhone,
      columns: [
        { header: "", dataKey: "dataKey" },
        { header: "", dataKey: "numKey" },
      ],
      columnStyles: {
        1: { cellWidth: "auto" },
      },

      didParseCell: (table) => {
        if (table.row.index === 0) {
          table.cell.styles.fillColor = "#ffffff";
        }
      },
      willDrawCell: (data) => {
        if (data.section === "head") {
          if (data.table.pageCount > 1) {
            return false;
          }
        }
        if (data.column.index === 0 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
        if (data.column.index === 1 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
        if (data.column.index === 2 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
        if (data.column.index === 3 && data.cell.section === "body") {
          pdfDoc.setFont("helvetica", "bold");
        }
      },
    });

    let totalInfo = `${IntlUtil.getText(
      intlNamespace,
      "content.total"
    )} (${IntlUtil.getText(intlNamespace, "content.taxNotIncluded")})`;

    autoTable(pdfDoc, {
      startY: pdfDoc.lastAutoTable.finalY + 15,
      tableLineColor: websiteTemplate?.generalSettings?.sectionBorderColor,
      tableLineWidth: 1,
      columnStyles: {
        0: { cellWidth: 270, fillColor: "#ffffff" },
        1: { fillColor: "#ffffff" },
        2: { fillColor: "#ffffff" },
        3: { cellWidth: "auto", fillColor: "#ffffff" },
        4: { cellWidth: "auto", fillColor: "#ffffff" },
        5: { cellWidth: "auto", fillColor: "#ffffff" },
      },
      body: tableRecords,
      columns: tableColumns,
      foot: [
        {
          productDetails: totalInfo,
          customerPriceAmount: customerTotalPrice(
            currencySymbol,
            customerPrice
          ),
        },
      ],
      didParseCell: (table) => {
        if (
          table.column.dataKey === "customerPriceUnitPrice" ||
          table.column.dataKey === "customerPriceAmount"
        ) {
          table.cell.styles.halign = "right";
        }
        if (table.column.dataKey === "quantity") {
          table.cell.styles.halign = "center";
        }
        if (table.section === "head") {
          table.cell.styles.fillColor = "#d0d0d0";
          table.cell.styles.textColor = "#231f20";
        } else if (table.section === "foot") {
          table.cell.styles.fillColor = "#d0d0d0";
          table.cell.styles.textColor = "#231f20";
        } else {
          let rows = table.table.body;
          if (table.row.index === rows.length - 1) {
            table.cell.styles.fillColor = "#ffffff";
          }
          table.cell.styles.lineColor =
            websiteTemplate?.generalSettings?.sectionBorderColor;
          table.cell.styles.lineWidth = {
            top: 1,
          };
        }
      },
      willDrawCell: (data) => {
        if (data.section === "head") {
          if (data.table.pageCount > 1) {
            return false;
          }
        } else {
          data.cell.styles.lineColor =
            websiteTemplate?.generalSettings?.sectionBorderColor;
          data.cell.styles.lineWidth = { top: 1 };
        }
        if (data.column.dataKey === "quantity") {
          data.cell.text?.toLocaleString(undefined, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          });
        }
      },
    });
    let splitLines = "";
    let previousTextDimensions = pdfDoc.lastAutoTable.finalY + 10;
    if (comments && comments.length > 0) {
      splitLines = pdfDoc.splitTextToSize(comments, 510);
      if (
        pdfDoc.getCurrentPageInfo().pageContext.mediaBox.topRightY - 50 <=
        pdfDoc.getTextDimensions(splitLines).h + previousTextDimensions
      ) {
        pdfDoc.addPage();
        previousTextDimensions =
          previousTextDimensions + pdfDoc.getTextDimensions(splitLines).h + 20;
        pdfDoc.text(40, previousTextDimensions, splitLines);
      } else {
        pdfDoc.text(40, previousTextDimensions + 20, splitLines);
        previousTextDimensions =
          previousTextDimensions + pdfDoc.getTextDimensions(splitLines).h + 20;
      }
    }

    pdfDoc.setFontSize(12);
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.setTextColor("#231f20");
    previousTextDimensions = previousTextDimensions + 25;

    if (
      quoteEstimatorInfo &&
      quoteEstimatorInfo.resourceRecords &&
      quoteEstimatorInfo.resourceRecords.length > 0
    ) {
      let checkedGeneralDocRecords = [];
      quoteEstimatorInfo.resourceRecords.forEach((rec) => {
        checkedGeneralDocRecords.push({
          documentTitle: rec.resourceTitle,
          documentLink: rec?.resourceUrl,
        });
      });
      autoTable(pdfDoc, {
        margin: { left: 35 },
        startY: previousTextDimensions,
        body: [...checkedGeneralDocRecords],
        columns: [
          {
            header: IntlUtil.getText(intlNamespace, "content.resources"),
            dataKey: "documentTitle",
          },
        ],
        didParseCell: (table) => {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#ffffff";
            table.cell.styles.textColor = "#231f20";
          } else {
            table.cell.styles.fillColor = "#ffffff";
          }
        },
        willDrawCell: (data) => {
          if (data.section === "head") {
            if (data.table.pageCount > 1) {
              return false;
            }
          }
          if (data.column.index === 0 && data.cell.section === "body") {
            pdfDoc.setTextColor(0, 81, 124);
            pdfDoc?.textWithLink(
              data.row.raw.documentTitle,
              data.cell.x + 10,
              data.cell.y + 10,
              { url: data.row.raw.documentLink }
            );
          }
        },
      });
      previousTextDimensions = pdfDoc.lastAutoTable.finalY + 10;
    }

    if (
      quoteEstimatorInfo &&
      quoteEstimatorInfo.onboardServiceDocumentRecords &&
      quoteEstimatorInfo.onboardServiceDocumentRecords.length > 0
    ) {
      let checkedOnboardDocRecords = [];
      let selectedCountries = [];
      let requiredDocuments = [];
      await quoteEstimatorInfo.onboardServiceDocumentRecords.forEach((rec) => {
        if (rec.documents && rec.documents.length > 0) {
          selectedCountries.push(rec.category);
          checkedOnboardDocRecords.push({
            documentTitle: rec.category,
            documentLink: "#",
          });

          rec.documents.forEach((doc) => {
            requiredDocuments.push(doc);
            checkedOnboardDocRecords.push({
              documentTitle: doc.documentTitle,
              documentLink: doc?.documentUrl,
            });
          });
        }
      });
      if (selectedCountries.length > 0 && requiredDocuments.length > 0) {
        autoTable(pdfDoc, {
          margin: { left: 35 },
          startY: previousTextDimensions,
          body: [...checkedOnboardDocRecords],

          columns: [
            {
              header: IntlUtil.getText(
                intlNamespace,
                "content.requiredDocToOnboardService"
              ),
              dataKey: "documentTitle",
            },
          ],
          didParseCell: (table) => {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#ffffff";
              table.cell.styles.textColor = "#231f20";
            } else {
              table.cell.styles.fillColor = "#ffffff";
            }
          },
          willDrawCell: (data) => {
            if (data.section === "head") {
              if (data.table.pageCount > 1) {
                return false;
              }
            }
            if (data.column.index === 0 && data.cell.section === "body") {
              if (
                selectedCountries.includes(data.row.raw.documentTitle) === true
              ) {
                pdfDoc.setTextColor(0, 0, 0);
                pdfDoc.setFont("helvetica", "bold");
              } else {
                pdfDoc.setTextColor(0, 81, 124);
                pdfDoc?.textWithLink(
                  data.row.raw.documentTitle,
                  data.cell.x + 10,
                  data.cell.y + 10,
                  { url: data.row.raw.documentLink }
                );
              }
            }
          },
        });
        previousTextDimensions = pdfDoc.lastAutoTable.finalY + 10;
      }
    }

    if (quoteEstimatorInfo && quoteEstimatorInfo?.subscriberQuoteRecords) {
      if (quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount) {
        let businessName =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount
            ?.businessName ?? "";
        let customerDomains =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount
            ?.customerDomains ?? "";
        let tenantId =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount
            ?.tenantId ?? "";
        let streetAddress1 =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.address
            ?.addLine1 ?? "";
        let streetAddress2 =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.address
            ?.addLine2 ?? "";
        let city =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.address
            ?.city ?? "";
        let stateName =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.address
            ?.state ?? "";
        let postalCode =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.address
            ?.postalCode ?? "";
        let country =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.address
            ?.country ?? "";
        let firstName =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.contact
            ?.firstName ?? "";
        let lastName =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.contact
            ?.lastName ?? "";
        let email =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.contact
            ?.email ?? "";
        let phone =
          quoteEstimatorInfo?.subscriberQuoteRecords?.subscriberAccount?.contact
            ?.phone ?? "";

        let paymentInfo = [
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.businessName") +
              ": " +
              businessName,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.customerDomains") +
              ": " +
              customerDomains,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.tenantId") +
              ": " +
              tenantId,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.streetAddress1") +
              ": " +
              streetAddress1,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.streetAddress2") +
              ": " +
              streetAddress2,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.city") + ": " + city,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.state") +
              ": " +
              stateName,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.zipCode") +
              ": " +
              postalCode,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.country") +
              ": " +
              country,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.firstName") +
              ": " +
              firstName,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.lastName") +
              ": " +
              lastName,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.email") + ": " + email,
          },
          {
            documentTitle:
              IntlUtil.getText(intlNamespace, "content.phone") + ": " + phone,
          },
        ];
        autoTable(pdfDoc, {
          margin: { left: 35 },
          startY: previousTextDimensions + 15,
          body: [...paymentInfo],
          columns: [
            {
              header: IntlUtil.getText(
                intlNamespace,
                "content.customerAccountDetails"
              ),
              dataKey: "documentTitle",
            },
          ],
          didParseCell: (table) => {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#ffffff";
              table.cell.styles.textColor = "#231f20";
              table.cell.styles.fontSize = 11;
              table.cell.styles.cellPadding = { bottom: 10, left: 4 };
            } else {
              table.cell.styles.fillColor = "#ffffff";
            }
          },
          willDrawCell: (data) => {
            if (data.section === "head") {
              if (data.table.pageCount > 1) {
                return false;
              }
            }
          },
        });
        previousTextDimensions = pdfDoc.lastAutoTable.finalY + 10;
      }
      if (quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo) {
        if (
          quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo
            ?.paymentType ===
          OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.ORDER_TYPE
            .PURCHASE_ORDER
        ) {
          let paymentType =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo
              ?.paymentType ?? "";
          let poNumber =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.transactionNumber ?? "";
          let name =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.name ?? "";
          let email =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.email ?? "";
          let phone =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.phone ?? "";
          let paymentInfo = [
            {
              documentTitle: IntlUtil.getSubstituteText(
                intlNamespace,
                "content.paymentType",
                [
                  {
                    key: "<PAYMENT_TYPE>",
                    value: paymentType,
                  },
                ]
              ),
            },
            {
              documentTitle:
                IntlUtil.getText(intlNamespace, "content.poNumber") +
                ": " +
                poNumber,
            },
            {
              documentTitle:
                IntlUtil.getText(intlNamespace, "content.name") + ": " + name,
            },
            {
              documentTitle:
                IntlUtil.getText(intlNamespace, "content.email") + ": " + email,
            },
            {
              documentTitle:
                IntlUtil.getText(intlNamespace, "content.phone") + ": " + phone,
            },
          ];
          autoTable(pdfDoc, {
            margin: { left: 35 },
            startY: previousTextDimensions + 15,
            body: [...paymentInfo],
            columns: [
              {
                header: IntlUtil.getText(
                  intlNamespace,
                  "content.paymentDetails"
                ),
                dataKey: "documentTitle",
              },
            ],
            didParseCell: (table) => {
              if (table.section === "head") {
                table.cell.styles.fillColor = "#ffffff";
                table.cell.styles.textColor = "#231f20";
                table.cell.styles.fontSize = 11;
                table.cell.styles.cellPadding = { bottom: 10, left: 4 };
              } else {
                table.cell.styles.fillColor = "#ffffff";
              }
            },
            willDrawCell: (data) => {
              if (data.section === "head") {
                if (data.table.pageCount > 1) {
                  return false;
                }
              }
            },
          });
        } else {
          let paymentType =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo
              ?.paymentType ?? "";
          let referenceNumber =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.transactionNumber ?? "-";
          let paymentStatus =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.transactionStatus ?? "";
          let amount =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.paymentAmount ?? "";
          let currenySymbol =
            quoteEstimatorInfo?.subscriberQuoteRecords?.paymentInfo?.paymentData
              ?.currencySymbol;

          let paymentInfo = [
            {
              documentTitle: IntlUtil.getSubstituteText(
                intlNamespace,
                "content.paymentType",
                [
                  {
                    key: "<PAYMENT_TYPE>",
                    value: paymentType,
                  },
                ]
              ),
            },
            {
              documentTitle: IntlUtil.getSubstituteText(
                intlNamespace,
                "content.transactionId",
                [
                  {
                    key: "<TRANSACTION_ID>",
                    value: referenceNumber ?? "-",
                  },
                ]
              ),
            },
            {
              documentTitle: IntlUtil.getSubstituteText(
                intlNamespace,
                "content.paymentStatus",
                [
                  {
                    key: "<PAYMENT_STATUS>",
                    value:
                      paymentStatus?.toLowerCase() !==
                      OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE?.toLowerCase()
                        ? capitalizeLetter(paymentStatus)
                        : OperatorConnectConstants.PAYMENT_STATUS.UNAVAILABLE,
                  },
                ]
              ),
            },
            {
              documentTitle: IntlUtil.getSubstituteText(
                intlNamespace,
                "content.amountPaid",
                [
                  {
                    key: "<AMOUNT_PAID>",
                    value: `${currenySymbol}${PageUtil.setLocaleFormat(
                      parseFloat(amount),

                      OperatorConnectConstants.GENERAL.FORMATTER_TYPE.NUMBER,
                      2,
                      2
                    )}`,
                  },
                ]
              ),
            },
          ];
          autoTable(pdfDoc, {
            margin: { left: 35 },
            startY: previousTextDimensions + 15,
            body: [...paymentInfo],
            columns: [
              {
                header: IntlUtil.getText(
                  intlNamespace,
                  "content.paymentDetails"
                ),
                dataKey: "documentTitle",
              },
            ],
            didParseCell: (table) => {
              if (table.section === "head") {
                table.cell.styles.fillColor = "#ffffff";
                table.cell.styles.textColor = "#231f20";
                table.cell.styles.fontSize = 11;
                table.cell.styles.cellPadding = { bottom: 10, left: 4 };
              } else {
                table.cell.styles.fillColor = "#ffffff";
              }
            },
            willDrawCell: (data) => {
              if (data.section === "head") {
                if (data.table.pageCount > 1) {
                  return false;
                }
              }
            },
          });
        }
      }
    }

    pdfDoc.save(
      `${IntlUtil.getText(intlNamespace, "content.quote")}_${
        quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber
          ? quoteEstimatorInfo?.subscriberQuoteRecords?.quoteNumber
          : moment(new Date()).format("YYYYMMDDHHMMSS")
      }`
    );
  }
}

export function renderTotalAmount (currency,amount)  {
  let numberFormat = new Intl.NumberFormat("en", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });
  let amountTotal = numberFormat.format(amount);
  return amountTotal;
};
