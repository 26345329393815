import {
  QUOTE_PROGRESS_PAGE,
  QUOTE_ESTIMATOR,
  PUBLISHER_KEY,
  CONNECTED_ACCOUNT,
  ORDER_PAYMENT_INFO,
  SELECTED_PRODUCT_ID,
  QUOTE_QUOTE_ESTIMATOR_RESET,
  
} from "../constants";

const INIT_STATE = {
  quoteProgressPage: 0,
  quoteEstimatorInfo: null,
  orderPublisherKey: "",
  orderConnectedAccount: "",
  orderPaymentInfo: null,
  selectedProductId: null,
};

const quoteReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
  case QUOTE_PROGRESS_PAGE:
      return {
        ...state,
        quoteProgressPage: action.payload,
      };
    case QUOTE_ESTIMATOR:
      return {
        ...state,
        quoteEstimatorInfo: action.payload,
      };
    case PUBLISHER_KEY:
      return {
        ...state,
        orderPublisherKey: action.payload,
      };
    case CONNECTED_ACCOUNT:
      return {
        ...state,
        orderConnectedAccount: action.payload,
      };
    case ORDER_PAYMENT_INFO:
      return {
        ...state,
        orderPaymentInfo: action.payload,
      };
    case SELECTED_PRODUCT_ID:
      return {
        ...state,
        selectedProductId: action.payload,
      };
    case QUOTE_QUOTE_ESTIMATOR_RESET:
      return {
        ...state,
        quoteEstimatorInfo: action.payload,
      };
    default:
      return state;
  }
};

export default quoteReducer;
