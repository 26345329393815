import { Text, DefaultButton } from "@fluentui/react";
import { DownloadIcon } from "@fluentui/react-icons";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import { checkIdentitySession } from "../../../core/actions/common-actions";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import { QuoteEstimatorTableColumns } from "../settings/quote-estimator-table-columns";
import { QuotePOFormFields } from "../settings/quote-user-form-fields";
import { quoteDownloadPdf } from "../actions/quote-action";
import QuoteBuilderEstimator from "../helper/quote-builder-estimator";
import TelemetryUtil from "../../../core/helpers/telemetry-util";
import CustomStyles from "../../common/helpers/custom-styles";
import { withTranslation } from "react-i18next";
import QuoteServiceTable from "../helper/quote-service-table";

class OrderConfirmation extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _intl_ns_oc_quote = "oc_quote";
  _intl_ns_oc_common = "oc_common";

  constructor(props) {
    super(props);
    this.quoteFromUploadRef = React.createRef();
    this.state = {
      paymentTabs: OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS,
      tabSelected:
        OperatorConnectConstants.QUOTE.ORDER_CHECKOUT.PAYMENT_TABS[0],
      attachment: null,
      isChecked: false,
      companyPoFormInputFIelds: this.initCompanyPOFormInputFields(),
      isCompanyPoFormFilled: false,
      tableHeaderColumns: this.initQuoteEstimatorheaderColumns(),
      quoteFileErrors: null,
      isPageDataFetched: false,
      isPaymentDialogHidden: true,
      websiteTemplate: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtil.scrollToTop();
    TelemetryUtil.trackPageView(
      IntlUtil.getText(this._intl_ns_oc_quote, "title.orderConfirmation")
    );
    await this.setStateAsync({
      websiteTemplate: this.props.webTemplateConfigData?.websiteTemplate,
    });
    await this.props.setPageExit(false);
    localStorage.removeItem("persist:root");
    await this.setStateAsync({ isPageDataFetched: true });
    //await this.loadPageData();
  }

  // loadPageData=async()=>{
  //     if(this.props.quoteEstimatorInfo&&this.props.quoteEstimatorInfo.subscriberDetails){
  //         await this.setFormFieldValues(this.props.quoteEstimatorInfo.subscriberDetails);
  //     }
  // }
  initQuoteEstimatorheaderColumns = () => {
    let tableHeaderColumns = [];
    tableHeaderColumns = [
      ...tableHeaderColumns,
      ...QuoteEstimatorTableColumns(this._intl_ns_oc_quote),
    ];
    return tableHeaderColumns;
  };
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      IntlUtil.getText(
        this._intl_ns_common,
        "notification.warning.requestCancelled"
      )
    );
  }

  initCompanyPOFormInputFields = () => {
    let refCollection = {};
    return QuotePOFormFields(refCollection);
  };

  capitalizeLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleSavePDF = async () => {
    let checkTokenSessionExpired = checkIdentitySession(this._cancelToken);
    if (checkTokenSessionExpired === true) {
      await quoteDownloadPdf(
        this.state.websiteTemplate,
        this.props.quoteEstimatorInfo,
        this._intl_ns_oc_quote,
        this._intl_ns_oc_common,
        this.props.communicationPlatformsData
      );
    }
  };

  renderQuoterBuilderEstimator = (intlNamespace, quoteEstimatorInfo) => {
    return (
      <>
        <div className="page-frame-content">
          <QuoteBuilderEstimator
            intlNamespace={this._intl_ns_oc_quote}
            quoteEstimatorInfo={this.props.quoteEstimatorInfo}
          />
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div
          className="page-frame-content frame-content-quotes"
          ref={this.orderCheckoutRef}
        >
          <div>
            <span
              className={`m-l-10 quote-page-text-wrapper  ${CustomStyles.getStyle(
                "font-family",
                this.state.websiteTemplate
              )}`}
            >
              {IntlUtil.getText(
                this._intl_ns_oc_quote,
                "notification.info.thankyouForOrdering"
              )}
            </span>
          </div>
          <div className="m-t-10 quote-page-text-wrapper">
            <span
              className={
                "text-fw-semibold text-fs-large m-l-10 " +
                CustomStyles.getStyle(
                  this.state.websiteTemplate?.templateCode
                    ?.toLowerCase()
                    .trim() !==
                    OperatorConnectConstants.TEMPLATE_CODE.TEMPLATE_THREE?.toLowerCase().trim()
                    ? "text-fc-primary"
                    : "text-fc-secondary",
                  this.state.websiteTemplate
                )
              }
            >
              {IntlUtil.getSubstituteText(
                this._intl_ns_oc_quote,
                "content.orderReferenceNumber",
                [
                  {
                    key: "<QUOTE_NUMBER>",
                    value:
                      this.props?.quoteEstimatorInfo?.subscriberQuoteRecords
                        ?.quoteNumber ?? " ",
                  },
                ]
              )}
            </span>
          </div>
        </div>
        <div className="m-t-10 m-l-10 quote-page-text-wrapper">
          <Text>
            {IntlUtil.getSubstituteText(
              this._intl_ns_oc_quote,
              "content.orderText",
              [
                {
                  key: "<EMAIL>",
                  value:
                    this.props?.quoteEstimatorInfo?.subscriberQuoteRecords
                      ?.customerEmail ?? " ",
                },
              ]
            )}
          </Text>
        </div>
        <div
          className={`m-b-20 ${CustomStyles.getStyle(
            "page-content-separator",
            this.state.websiteTemplate
          )}`}
        ></div>
        {/* {this.renderQuoterBuilderEstimator(
          this._intl_ns_oc_quote,
          this.props.quoteEstimatorInfo
        )} */}
        <QuoteServiceTable
          {...this.props}
          websiteTemplate={this.state.websiteTemplate}
          history={this.props.history}
          quoteType={this.state.selectedQuoteTypeKey}
          isEditable={false}
          handleQuoteEdit={(item) => this.handleEditServiceRecord(item)}
          handleQuoteDelete={(item) =>
            this.handleDeleteServiceRecordDialog(item)
          }
          intlNamespace={this._intl_ns_oc_quote}
          showTitle={true}
        />
        <div>
          <div
            className={`${CustomStyles.getStyle(
              "page-content-separator",
              this.state.websiteTemplate
            )}`}
          ></div>
          <div style={{ paddingLeft: "2px" }}>
            <DefaultButton
              onClick={this.handleSavePDF}
              className="m-l-5 m-t-20 page-frame-button"
              iconProps={{iconName:"Download"}}
            >
              {IntlUtil.getText(
                this._intl_ns_oc_quote,
                "content.downloadOrder"
              )}
            </DefaultButton>
          </div>
        </div>
        {/* <AppPageTitle
          pageTitle={IntlUtil.getText(
            this._intl_ns_oc_quote,
            "title.orderConfirmation"
          )}
        /> */}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  communicationPlatformsData:state.generalStore.communicationPlatformsData

  // countryStatesData:state.generalStore.countryStatesData
});
const mapActionToProps = {
  // setQuoteProgressPage
};

export default withTranslation()(
  connect(mapStateToProps, mapActionToProps)(OrderConfirmation)
);
