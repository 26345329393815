import React, { useEffect, useState, useMemo } from "react";
import IntlUtil from "../../../core/helpers/intl-util";
import PageUtil from "../../../core/helpers/page-util";
import {
  DetailsList,
  DetailsListLayoutMode,
  IconButton,
  SelectionMode,
  TooltipHost,
  TooltipOverflowMode,
} from "@fluentui/react";
import {
  getQuote,
  getQuotesOrdersSearch,
  setQuoteProgressPage,
  setQuoteEstimatorInfoProductRecords,
  setQuoteEstimatorInfoResourceRecords,
  setQuoteEstimatorInfoServiceAndConnectionRecords,
  setQuoteEstimatorInfoServiceAndConnectionCost,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setQuoteEstimatorInfoOnboardServiceDocumentRecords,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  resetQuoteEstimatorInfo,
  quoteDownloadPdf,
} from "../actions/quote-action";
import { AppConfigProps } from "../../../core/settings/app-config";
import { QuoteOrderTableColumns } from "../settings/quote-order-table-columns";
import moment from "moment";
import PageOverlayLoader from "../../common/helpers/page-overlay-loader";
import _ from "lodash";
import { checkIdentitySession } from "../../../core/actions/common-actions";
import { OperatorConnectConstants } from "../../common/settings/operator-connect-constants";
import axios from "axios";
import { manageError } from "../../../core/actions/common-actions";
import { OperatorConnectURLProps } from "../../common/settings/operator-connect-urls";
import { connect } from "react-redux";
import CustomStyles from "../../common/helpers/custom-styles";
import Welcome from "../../common/components/welcome";
import PageHeader from "../../common/helpers/page-header";

const QuoteOrders = (props) => {
  const _intl_ns_common = "oc_common";
  const _intl_ns_oc_quote = "oc_quote";
  const _axiosSource = axios.CancelToken.source();
  const _cancelToken = { cancelToken: _axiosSource.token };
  let tableHeaderColumns = QuoteOrderTableColumns(_intl_ns_oc_quote);
  let [isPageDataFetched, setPageDataFetched] = useState(false);
  let [isListDataFetched, setListDataStatus] = useState(true);
  const [websitePageTitle, setWebsitePageTitle] = useState({});
  let [quoteRecords, setQuoteList] = useState([]);
  let params = new URLSearchParams(props.history.location.search);
  let keyword = params.get("search");

  useEffect(() => {
    PageUtil.scrollToTop();
    const getData = async () => {
      setPageDataFetched(false);
      let websiteTitle =
        props?.webTemplateConfigData?.websiteTemplate?.contentPages.find(
          (rec) => rec.pageCode?.toLowerCase().trim() === "oc-p4"
        );
      setWebsitePageTitle(websiteTitle);
      if (keyword?.trim() !== "" && props.selectedLanguageData.key) {
        await fetchQuotesOrdersList(
          props.selectedLanguageData.key,
          keyword?.trim()
        );
      } else {
        setQuoteList([]);
      }
      setPageDataFetched(true);
    };
    getData();
  }, [keyword, props.selectedLanguageData.key]);

  const capitalizeLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleSavePDF = async (quoteRecord) => {
    let checkTokenSessionExpired = checkIdentitySession(_cancelToken);
    if (checkTokenSessionExpired === true) {
      await quoteDownloadPdf(
        props?.webTemplateConfigData?.websiteTemplate,
        quoteRecord,
        _intl_ns_oc_quote,
        _intl_ns_common,
        props.communicationPlatformsData
      );
    }
  };

  const fetchQuotesOrdersList = async (
    languageCode,
    Keyword,
    extractType = null
  ) => {
    await getQuotesOrdersSearch(
      languageCode,
      Keyword,
      extractType,
      _cancelToken
    )
      .then((res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const records = _.orderBy(
            res.data.records,
            ["createTimestamp"],
            "desc"
          );
          setQuoteList(records);
          setPageDataFetched(true);
        }
      })
      .catch(async (err) => {
        setPageDataFetched(true);
        await manageError(err, props.history);
      });
  };

  const getQuotesDetails = async (item, actionStatus) => {
    setListDataStatus(false);
    await getQuote(props.selectedLanguageData?.key, item.quoteId, _cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await setProductQuote(res.data.result, actionStatus);
        }
      })
      .catch(async (err) => {
        setListDataStatus(true);
        await manageError(err, props.history);
      });
  };

  const handleQuoteProductSubmit = async (item) => {
    if (item.processStatus === "QuoteCreate") {
      props.history.push({
        pathname: OperatorConnectURLProps.orderManagement.quoteAdd,
        state: {
          processStatus: "QuoteCreate",
          orderStatus: true,
        },
      });
      return;
    }
    if (item.processStatus === "SubscriberCreate") {
      props.history.push({
        pathname: OperatorConnectURLProps.orderManagement.quoteAdd,
        state: {
          processStatus: "SubscriberCreate",
          orderStatus: true,
        },
      });
      return;
    }
  };

  const handleCalculateDiscount = (amount, discount) => {
    let discountedAmount = 0;
    let disc = discount ? discount : 0;
    let amt = amount ? amount : 0;
    discountedAmount = amount - (amt * disc) / 100;
    return parseFloat(Number(discountedAmount).toFixed(2));
  };

  const setProductQuote = async (result, actionStatus) => {
    let quoteEstimatorInfo = {
      ...props.quoteEstimatorInfo,
      resourceRecords: [],
      subscriberDetails: null,
      subscriberQuoteRecords: null,
    };
    props.resetQuoteEstimatorInfo(quoteEstimatorInfo);
    let allServiceRecords = [];
    let infoSubscriberQuoteRecords = [];
    let infoServiceAndConnectionRecords = [];
    let infoServiceAndConnectionCost = null;
    let infoQuoteSubscriberDetails = null;
    if (result.products && result.products.length > 0) {
      result.products.forEach((item) => {
        item.selectedCountry = item?.availabilityName;
        item.connection = item?.planName?.trim();
        let nrcSellingPrice = 0;
        let mrcSellingPrice = 0;
        let pricing = [];
        if (item.pricing && item.pricing.length > 0) {
          item.pricing?.forEach(async (price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              mrcSellingPrice = handleCalculateDiscount(
                parseFloat(price?.retailPrice ?? "0").toFixed(2),
                parseFloat(0).toFixed(2)
              );
              pricing.push({
                chargeType: price.chargeType,
                pricingId: price.pricingId,
                chargeName: price.chargeName,
                maximumPrice: price.maximumPrice,
                sellingPrice: mrcSellingPrice,
                discountPercent: parseFloat(0),
                mrcPartnerBuyingPrice: price?.buyingPrice,
              });
            } else if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              nrcSellingPrice = handleCalculateDiscount(
                parseFloat(price?.retailPrice ?? "0").toFixed(2),
                parseFloat(0).toFixed(2)
              );
              pricing.push({
                chargeType: price.chargeType,
                chargeName: price.chargeName,
                pricingId: price.pricingId,
                maximumPrice: price.maximumPrice,
                sellingPrice: nrcSellingPrice,
                discountPercent: parseFloat(0),
                nrcPartnerBuyingPrice: price?.buyingPrice,
              });
            }
          });
        }
        infoServiceAndConnectionRecords.push({ ...item, pricing: pricing });
      });
    }

    if (
      infoServiceAndConnectionRecords &&
      infoServiceAndConnectionRecords.length > 0
    ) {
      let nrcSubTotal = 0;
      let mrcSubTotal = 0;
      let total = 0;
      infoServiceAndConnectionRecords.forEach(async (obj, index) => {
        let mrcCount = 0;
        let nrcCount = 0;
        let nrcretailPrice = 0;
        let mrcretailPrice = 0;
        let mrcDidCost = null;
        if (obj.pricing && obj.pricing.length > 0) {
          obj.pricing.forEach((price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              if (price?.chargeName?.toLowerCase()?.trim() === "did charges") {
                mrcDidCost = price.retailPrice;
              }
              mrcretailPrice = price.retailPrice;
              mrcCount =
                mrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
            } else if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              nrcretailPrice = price.retailPrice;
              nrcCount =
                nrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
            }
          });
        }
        nrcSubTotal = nrcSubTotal + nrcCount;
        mrcSubTotal = mrcSubTotal + mrcCount;
        allServiceRecords.push({
          ...obj,
          id: index,
          mrc: mrcCount.toString(),
          nrc: nrcCount.toString(),
          nrcretailPrice: nrcretailPrice?.toString(),
          mrcretailPrice: mrcretailPrice?.toString(),
          mrcDidCost: mrcDidCost?.toString(),
        });
      });
      total = total + nrcSubTotal + mrcSubTotal;
      infoServiceAndConnectionCost = {
        nrcSubTotal: nrcSubTotal?.toString(),
        mrcSubTotal: mrcSubTotal?.toString(),
        total: total?.toString(),
      };

      infoQuoteSubscriberDetails = {
        quoteName: result.customerName,
        email: result.customerEmail,
        phone: result.customerPhone,
      };
      quoteEstimatorInfo = {
        ...quoteEstimatorInfo,
        productRecords: result.products,
        serviceAndConnectionRecords: [...allServiceRecords],
        serviceNrcSubTotalCost: infoServiceAndConnectionCost?.nrcSubTotal,
        serviceMrcSubTotalCost: infoServiceAndConnectionCost?.mrcSubTotal,
        serviceTotalCost: infoServiceAndConnectionCost?.total,
        subscriberDetails: infoQuoteSubscriberDetails,
      };
    }

    let quoteRecord = { ...result, products: result.products };
    let resourceRecords = [];
    let onboardServiceDocumentRecords = [];
    if (
      quoteRecord &&
      quoteRecord.resources &&
      quoteRecord.resources.length > 0
    ) {
      quoteRecord.resources.forEach((rec) => {
        resourceRecords.push(rec);
      });
    }
    if (
      quoteRecord &&
      quoteRecord.evidences &&
      quoteRecord.evidences.length > 0
    ) {
      const groups = _.groupBy(quoteRecord.evidences, "category");
      Object.entries(groups).forEach(([category, documents]) => {
        onboardServiceDocumentRecords.push({
          category: category,
          documents: documents,
        });
      });
    }
    let nrcSubTotal = 0;
    let mrcSubTotal = 0;
    let total = 0;
    if (
      quoteRecord &&
      quoteRecord.products &&
      quoteRecord.products.length > 0
    ) {
      quoteRecord.products.forEach((obj, index) => {
        let mrcCount = 0;
        let nrcCount = 0;
        let nrcPartnerCost = 0;
        let mrcPartnerCost = 0;
        let mrcDiscount = 0;
        let nrcDiscount = 0;
        if (obj.pricing && obj.pricing.length > 0) {
          obj.pricing.forEach((price) => {
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.ONE_TIME?.toLowerCase().trim()
            ) {
              nrcCount =
                nrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
              nrcPartnerCost = price?.retailPrice ?? "0";
            }
            if (
              price.chargeType?.toLowerCase().trim() ===
              OperatorConnectConstants.QUOTE.CHARGE_TYPE.RECURRING?.toLowerCase().trim()
            ) {
              mrcCount =
                mrcCount +
                parseFloat(obj?.quantity ?? "0") *
                  parseFloat(price?.sellingPrice ?? "0");
              mrcPartnerCost = price?.retailPrice ?? "0";
            }
          });
        }

        nrcSubTotal = nrcSubTotal + nrcCount;
        mrcSubTotal = mrcSubTotal + mrcCount;
        allServiceRecords.push({
          ...obj,
          connection: obj?.planName,
          nrcPartnerCost: nrcPartnerCost,
          mrcPartnerCost: mrcPartnerCost,
          nrcDiscount: nrcDiscount,
          mrcDiscount: mrcDiscount,
          id: index,
          mrc: mrcCount?.toString(),
          nrc: nrcCount?.toString(),
        });
      });

      total = total + nrcSubTotal + mrcSubTotal;

      infoSubscriberQuoteRecords = {
        ...quoteRecord,
        products: allServiceRecords,
        nrcSubTotal: nrcSubTotal,
        mrcSubTotal: mrcSubTotal,
        totalCost: total,
        resourceRecords: resourceRecords,
        onboardServiceDocumentRecords: onboardServiceDocumentRecords,
      };

      quoteEstimatorInfo = {
        ...quoteEstimatorInfo,
        serviceNrcSubTotalCost: infoServiceAndConnectionCost?.nrcSubTotal,
        serviceMrcSubTotalCost: infoServiceAndConnectionCost?.mrcSubTotal,
        serviceTotalCost: infoServiceAndConnectionCost?.total,
        subscriberQuoteRecords: infoSubscriberQuoteRecords,
        subscriberDetails: infoQuoteSubscriberDetails,
        resourceRecords: resourceRecords,
        onboardServiceDocumentRecords: onboardServiceDocumentRecords,
      };
    }

    props.setQuoteEstimatorInfoSubscriberQuoteRecords(
      quoteEstimatorInfo,
      infoSubscriberQuoteRecords
    );
    setListDataStatus(true);
    setPageDataFetched(true);
    if (actionStatus === "quoteDownload") {
      await handleSavePDF(quoteEstimatorInfo);
    } else {
      await handleQuoteProductSubmit(result);
    }
  };

  const handleItemRender = (item, index, column) => {
    switch (column.key) {
      case "createTimestamp":
        return (
          <div>
            {moment(item.createTimestamp).format("MMM D, YYYY HH:mm:ss")}
          </div>
        );
      case "quoteNumber":
        return <span className="quote-link-text">{item.quoteNumber}</span>;
      case "actions":
        return (
          <>
            <TooltipHost
              content={IntlUtil.getText(
                _intl_ns_oc_quote,
                "content.tooltip.downloadTheQuote"
              )}
            >
              <IconButton
                className="quote-page-frame-icon"
                onClick={async () => {
                  await getQuotesDetails(item, "quoteDownload");
                }}
                iconProps={{ iconName: "Download" }}
              />
            </TooltipHost>
            {item.processStatus?.toLowerCase() ===
              OperatorConnectConstants.PROCESS_STATUS.QUOTE_CREATE.toLowerCase() ||
            item.processStatus?.toLowerCase() ===
              OperatorConnectConstants.PROCESS_STATUS.SUBSCRIBER_CREATE.toLowerCase() ? (
              <TooltipHost
                content={IntlUtil.getText(
                  _intl_ns_oc_quote,
                  "content.tooltip.submitTheQuote"
                )}
              >
                <IconButton
                  className="quote-page-frame-icon"
                  onClick={async () => {
                    await getQuotesDetails(item, "quoteSubmit");
                  }}
                  iconProps={{ iconName: "PageEdit" }}
                />
              </TooltipHost>
            ) : null}
          </>
        );

      default:
        return (
          <div>
            <TooltipHost
              overflowMode={TooltipOverflowMode.Self}
              hostClassName="invoice-description-text"
              content={item[column.fieldName]}
            >
              {item[column.fieldName]}
            </TooltipHost>
          </div>
        );
    }
  };

  return (
    <>
      {/* <AppPageTitle
        pageTitle={IntlUtil.getText(_intl_ns_oc_quote, "title.quoteOrOrder")}
      /> */}
      {props?.webTemplateConfigData?.websiteTemplate?.bannerDisplayStatus
        ?.toLowerCase()
        .trim() === OperatorConnectConstants.GENERAL.YES?.toLowerCase() ? (
        <Welcome {...props} />
      ) : null}
      <PageHeader
        {...props}
        showCurrencyStatus={false}
        websitePageTitle={websitePageTitle}
      />
      <div
        id="oc-page-container"
        className={CustomStyles.getStyle(
          "page-background",
          props.webTemplateConfigData?.websiteTemplate
        )}
      >
        {" "}
        <div className="page-frame-content">
          {keyword !== null &&
          keyword !== undefined &&
          keyword?.trim() !== "" ? (
            <>
              {isPageDataFetched ? (
                <div
                  className={
                    "page-frame-table " +
                    CustomStyles.getStyle(
                      "page-frame-table",
                      props.webTemplateConfigData?.websiteTemplate
                    )
                  }
                >
                  {quoteRecords && quoteRecords.length > 0 ? (
                    <DetailsList
                      columns={tableHeaderColumns}
                      setKey="quoteRecord"
                      items={quoteRecords}
                      selectionMode={SelectionMode.none}
                      layoutMode={DetailsListLayoutMode.justified}
                      onRenderItemColumn={handleItemRender}
                    />
                  ) : null}
                  {isPageDataFetched && quoteRecords.length === 0 ? (
                    <div className="p-10">
                      {IntlUtil.getText(
                        _intl_ns_oc_quote,
                        "notification.info.noDatafound"
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <PageOverlayLoader
          hidden={isPageDataFetched}
          label={IntlUtil.getText(_intl_ns_common, "content.loadingInprogress")}
        />
        <PageOverlayLoader
          hidden={isListDataFetched}
          label={IntlUtil.getText(_intl_ns_common, "content.loadingInprogress")}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quoteEstimatorInfo: state.quoteStore.quoteEstimatorInfo,
  webTemplateConfigData: state.generalStore.webTemplateConfigData,
  selectedLanguageData: state.generalStore.selectedLanguageData,
  communicationPlatformsData:state.generalStore.communicationPlatformsData

});
const mapActionToProps = {
  setQuoteEstimatorInfoProductRecords,
  setQuoteEstimatorInfoServiceAndConnectionRecords,
  setQuoteEstimatorInfoServiceAndConnectionCost,
  setQuoteEstimatorInfoQuoteSubscriberDetails,
  setQuoteEstimatorInfoOnboardServiceDocumentRecords,
  setQuoteEstimatorInfoSubscriberQuoteRecords,
  setQuoteEstimatorInfoResourceRecords,
  setQuoteProgressPage,
  resetQuoteEstimatorInfo,
};

export default connect(mapStateToProps, mapActionToProps)(QuoteOrders);
