export const APP_NAME = "APP_NAME";

//Quotes

export const QUOTE_ESTIMATOR = "QUOTE_ESTIMATOR";
export const QUOTE_PROGRESS_PAGE = "QUOTE_PROGRESS_PAGE";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const PUBLISHER_KEY = "PUBLISHER_KEY";
export const CONNECTED_ACCOUNT = "CONNECTED_ACCOUNT";
export const ORDER_PAYMENT_INFO = "ORDER_PAYMENT_INFO";
export const SELECTED_PRODUCT_ID = "SELECTED_PRODUCT_ID";
export const QUOTE_QUOTE_ESTIMATOR_RESET = "QUOTE_QUOTE_ESTIMATOR_RESET";

//General

export const COUNTRY_STATES_DATA = "COUNTRY_STATES_DATA";
export const WEB_TEMPLATE_CONFIG_DATA = "WEB_TEMPLATE_CONFIG_DATA";
export const LANGUAGE_DATA = "LANGUAGE_DATA";
export const CURRENCY_DATA = "CURRENCY_DATA";
export const SELECTED_LANGUAGE_DATA = "SELECTED_LANGUAGE_DATA";
export const SELECTED_CURRENCY_DATA = "SELECTED_CURRENCY_DATA";
export const TOKEN_EXPIRY_TIME = "TOKEN_EXPIRY_TIME";
export const TENANT_ID = "TENANT_ID";
export const COMMUNICATION_PLATFORMS="COMMUNICATION_PLATFORMS";

